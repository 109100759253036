import requests from "./reques"
import {post , get } from "./ajax"
// import {post , get } from "./Service"
export function logincaptcha () {
  return requests({
    url: '/login/captcha',
    mothod:"get",
    responseType: "blob"
  })
}

// 登录
export const login = data => post('user/login',data)

//注册 user/register
export const register = data => post('user/register',data)

// 注销登录 user/logout
export const logout = () => get('user/logout')

// 用户信息 user
export const user = () => get('user')

// 实名认证 info/save
export const infosave = data => post('info/save',data)

//实名认证信息 /info
export const info = () => get('info')

// 应用列表 /app
export const app = (data,params) => get('app',data,params)

//新增应用  app/save params
export const appsave = (data) => post('app/save',data)

//应用详情 app/read
export const appread = (data,params) => get('app/read',data,params)

//修改应用信息 app/update
export const appupdate = (data,params) => post('app/update',data,params)

//修改密码  user/uppwd
export const useruppwd = (data,params) => post('user/uppwd',data,params)

//修改手机号  user/changemobile
export const userchangemobile = (data,params) => post('user/changemobile',data,params)

//获取订单 order
export const order = (data,params) => get('order',data,params)

// 日账号  order/dayBill
export const orderdayBill = (data,params) => get('order/dayBill',data,params)

//月账单 order/monthBill
export const ordermonthBill = (data,params) => get('order/monthBill',data,params)

//修改手机号验证码  sms/send
export const smssend = (data,params) => post('sms/send',data,params)

// 首页本周数据  index/dashboard
export const indexdashboard = (data,params) => get('index/dashboard',data,params)

// 公告 index/notice
export const indexnotice = (data,params) => get('index/notice',data,params)

// 数据中心 dashboard
export const dashboard = (data,params) => get('dashboard',data,params)

// 修改邮箱验证码发送 ems/send
export const emssend = (data,params) => post('ems/send',data,params)

//修改邮箱 user/changeemail
export const userchangeemail = (data,params) => post('user/changeemail',data,params)

//忘记密码  user/resetpwd
export const userresetpwd = (data,params) => post('user/resetpwd',data,params)

//查看密匙  app/readAppSecret
export const appreadAppSecret = (data,params) => post('app/readAppSecret',data,params)

// 提现 transfer
export const transfer = (data,params) => get('transfer',data,params)

// 获取协议 explain/getConfig
export const explaingetConfig = () => get('explain/getConfig')

// 店铺类型列表 classify?page=1&limit=10
export const classify = (data,params) => get('classify',data,params)

// 上传图片  common/uploadBase64
export const commonuploadBase64 = (data,params) => post('common/uploadBase64',data,params)

// 修改渠道开关 user/setPayConfig
export const usersetPayConfig = (data,params) => post('user/setPayConfig',data,params)

//  user/getPayConf
export const usergetPayConf = (data,params) => get('user/getPayConf',data,params)

//  角色列表 group
export const group = (data,params) => get('group',data,params)

// 新增角色 group/save
export const groupsave = (data,params) => post('group/save',data,params)

// 修改角色信息 group/update
export const groupupdate = (data,params) => post('group/update',data,params)

// 删除角色 group/delete
export const groupdelete = (data,params) => post('group/delete',data,params)

// 子账号列表 user/subList
export const usersubList = (data,params) => get('user/subList',data,params)

// 获取树节点 group/getRuleTreeList
export const groupgetRuleTreeList = (data,params) => get('group/getRuleTreeList',data,params)

// 子账号添加 user/subRegister
export const usersubRegister = (data,params) => post('user/subRegister',data,params)

// 子账号编辑 user/subUpdate
export const usersubUpdate = (data,params) => post('user/subUpdate',data,params)

// 删除子账号 user/subDelete
export const usersubDelete = (data,params) => post('user/subDelete',data,params)

// 角色状态 group/modify
export const groupmodify = (data,params) => post('group/modify',data,params)

// 子账号状态 user/subModify
export const usersubModify = (data,params) => post('user/subModify',data,params)

// 银行卡 bankcard
export const bankcard = (data,params) => get('bankcard',data,params)

// 加密钱包 wallet
export const wallet = (data,params) => get('wallet',data,params)

// 支付宝 alipay
export const alipay = (data,params) => get('alipay',data,params)

// 添加银行卡 bankcard/add
export const bankcardadd = (data,params) => post('bankcard/add',data,params)

// 添加虚拟货币 wallet/add
export const walletadd = (data,params) => post('wallet/add',data,params)

// 添加支付宝 alipay/add
export const alipayadd = (data,params) => post('alipay/add',data,params)

// 商户提现记录 with
export const withlist = (data,params) => post('with',data,params)

// 申请提现 with/add
export const withadd = (data,params) => post('with/add',data,params)

// 银行卡删除 bankcard/delete
export const bankcarddelete = (data,params) => post('bankcard/delete',data,params)

// 虚拟钱包删除 wallet/delete
export const walletdelete = (data,params) => post('wallet/delete',data,params)

// 支付宝删除 alipay/delete
export const alipaydelete = (data,params) => post('alipay/delete',data,params)

//设置支付密码 user/setPayPwd
export const usersetPayPwd = (data,params) => post('user/setPayPwd',data,params)

// 提交建议 feedback/save
export const feedbacksave = (data,params) => post('feedback/save',data,params)