//对axios进行二次封装
import axios from "axios";
import config from './config';

//1:利用axios对象的方法create，去创建一个axios实例
//2:request就是axios，只不过稍微配置一下
const requests = axios.create({



  //配置对象
  //基础路径，发请求的时候，路径当中会出现api
  baseURL: config.BaseUrl,
  //代表请求超时的时间5S,超时停止
  timeout: 5000,
})

//请求拦截器:在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "X-Requested-With": "XMLHttpRequest",
  };
  config.headers = { ...headers, ...config.headers };
  // let token = localStorage.getItem("token");
  // if (token && token !== "") {
  //     config.headers["token"] = token;
  // }

  //请求拦截器:在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
  return config;
})

//响应拦截器
requests.interceptors.response.use((res) => {
  //成功的回调函数:服务器相应数据回来以后，响应拦截器可以检测到，可以做一些事情
  return res.data; //简化数据返回获取数据的调取

}, (error) => {
  //响应失败的回调函数
  console.log(error)
  return Promise.reject(new Error('faile'));
})

//对外暴露
export default requests